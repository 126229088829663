import React from 'react';
import logo from '../assets/images/Lenzify-Logo.svg';
import config from '../../config';


export default function HeaderMin({ title, heading, avatar }) {
  return (
    <section id="header-min">
      <header align="center">
      <img src={logo} alt="lenzify" width="350" height="150"/>
        <div style={{color:"white"}}>{config.heading}</div>
      </header>
    </section>
  );
}
